import { Component, Prop, Mixins } from "vue-property-decorator";
import UlContentHeader from "@/components/UlContentHeader.vue";
import UlBreadcrumbs from "@/components/UlBreadcrumbs.vue";
import NewEditNormal from "@/views/coupon/NewEditNormal.vue";
import NewEditLimitation from "@/views/coupon/NewEditLimitation.vue";
import NewEditBirthday from "@/views/coupon/NewEditBirthday.vue";
import RedirectWithAlert from "@/models/mixins/redirect-with-alert";
import Flash, { ErrorAlert } from "@/store/common/flash";
import ShopAgreementAPI from "@/store/shop-agreement/get";
import {GetRequest as GetAgreementRequest} from "@/api/shop-agreement/request";

@Component({
  components: {
    UlContentHeader,
    UlBreadcrumbs,
    NewEditNormal,
    NewEditLimitation,
    NewEditBirthday
  }
})
export default class NewEdit extends Mixins(RedirectWithAlert) {
  @Prop({ type: String, required: false })
  id?: string | null; // クーポンID

  // タイトル
  headingMain = "クーポン";
  headingSub = "Coupon";

  // パンくず
  get breadCrumbs() {
    if (this.isEdit) {
      return [
        { text: "アピール", disabled: true },
        { text: "クーポン", disabled: true },
        { text: "店舗一覧", disabled: false, to: { name: "coupon" } },
        {
          text: "クーポン一覧",
          disabled: false,
          to: {
            name: "coupon-list",
            params: { shopId: this.shopId },
            query: { shopName: this.shopName }
          }
        },
        { text: "編集", disabled: true }
      ];
    } else {
      return [
        { text: "店舗一覧", disabled: false, to: { name: "coupon" } },
        {
          text: "クーポン一覧",
          disabled: false,
          to: {
            name: "coupon-list",
            params: { shopId: this.shopId },
            query: { shopName: this.shopName }
          }
        },
        { text: "新規作成", disabled: true }
      ];
    }
  }

  tabItems = [
    {
      title: "通常クーポン",
      component: "NewEditNormal",
      disabled: false
    },
    {
      title: "初回限定クーポン",
      component: "NewEditLimitation",
      disabled: false
    },
    {
      title: "誕生日クーポン",
      component: "NewEditBirthday",
      disabled: false
    }
  ];

  // 契約状態
  agreements = {
    ap: false,
    line: false,
    web: false
  };

  /**
   * クエリパラメータから店舗IDを返却する（必須）
   */
  get shopId() {
    if (this.$route.query.shopId) {
      return this.$route.query.shopId;
    } else {
      return "";
    }
  }

  /**
   * クエリパラメータから店舗名を返却する（必須）
   */
  get shopName() {
    if (this.$route.query.shopName) {
      return this.$route.query.shopName;
    } else {
      return "";
    }
  }

  get isEdit() {
    return !!this.id;
  }

  get activeTab() {
    return this.$route.query.tab ? Number(this.$route.query.tab) : 0;
  }

  set activeTab(val) {
    let query = { ...this.$route.query };
    query.tab = String(val);
    this.$router.replace({ query: query }).then();
  }

  /**
   * 契約が未登録かの判定
   * @return true:契約が 未登録 false:契約が 登録済
   */
  isNotAgreement(): boolean {
    const result = this.agreements.ap === false && this.agreements.line === false && this.agreements.web === false;
    return result;
  }

  /**
   * tabItems の内容を再構築する
   */
  rebuildTabItems() {
    this.tabItems = [];
    this.tabItems.push({
      title: "通常クーポン",
      component: "NewEditNormal",
      disabled: false
    });
    if (this.agreements.ap === true || this.isNotAgreement() === true) {
      this.tabItems.push({
        title: "初回限定クーポン",
        component: "NewEditLimitation",
        disabled: false
      });
      this.tabItems.push({
        title: "誕生日クーポン",
        component: "NewEditBirthday",
        disabled: false
      });
    }
  }

  async created() {
    if (!this.shopId) {
      await this.$router.push({ name: "coupon" });
      await Flash.setErrorNow({
        message: "パラメータが不足しています。",
        showReloadButton: false
      } as ErrorAlert);
    } else {
      // 契約情報を取得する
      const requestAgrement = {
        shopId: this.shopId
      } as GetAgreementRequest;
      await ShopAgreementAPI.get(requestAgrement);
      if (!ShopAgreementAPI.isSuccess) {
        await Flash.setErrorNow({
          message: "",
          showReloadButton: true
        } as ErrorAlert);
        return;
      } else {
        let AgreementItems = ShopAgreementAPI.getagreementsList;
        AgreementItems.forEach((item: { agreementsType: number }) => {
          if (item.agreementsType == 1) {
            this.agreements.ap = true;
          } else if (item.agreementsType == 2) {
            this.agreements.line = true;
          } else if (item.agreementsType == 3) {
            this.agreements.web = true;
          }
        });
        // タブを再構築
        this.rebuildTabItems();
      }
    }
  }
}
