import service from "@/api/service";
import { GetResponse } from "@/api/write-shop/response";
import { GetRequest } from "@/api/write-shop/request";

/**
 * クーポン登録可能店舗名取得APIをコールします。
 *
 * @return GetResponse
 */
export async function get(getRequest: GetRequest) {
  const response = await service.post("/get-write-shop", getRequest);
  return response.data as GetResponse;
}
