import service from "@/api/service";
import {
  SearchRequest,
  DetailRequest,
  RegisterRequest,
  SortRequest,
  DeleteRequest,
  BarcodeFlgRequest
} from "@/api/coupon/request";
import {
  SearchResponse,
  DetailResponse,
  RegisterResponse,
  BarcodeFlgResponse,
} from "@/api/coupon/response";
import { CommonResponse } from "@/api/response";

/**
 * クーポン一覧画面APIをコールします。
 *
 * @param searchRequest 検索のリクエストボディ
 * @return SearchResponse
 */
export async function search(searchRequest: SearchRequest) {
  const response = await service.post("/search-coupon", searchRequest);
  return response.data as SearchResponse;
}

/**
 * クーポン詳細情報取得APIをコールします。
 *
 * @param detailRequest 検索のリクエストボディ
 * @return DetailResponse
 */
export async function detail(detailRequest: DetailRequest) {
  const response = await service.post("/detail-coupon", detailRequest);
  return response.data as DetailResponse;
}

/**
 * クーポン情報登録更新APIをコールします。
 *
 * @param registerRequest 検索のリクエストボディ
 * @return RegisterResponse
 */
export async function register(registerRequest: RegisterRequest) {
  const response = await service.post("/regist-coupon", registerRequest);
  return response.data as RegisterResponse;
}

/**
 * クーポンソート順更新APIをコールします。
 *
 * @param sortRequest
 * @return CommonResponse
 */
export async function sort(sortRequest: SortRequest) {
  const response = await service.post("/sort-coupon", sortRequest);
  return response.data as CommonResponse;
}

/**
 * クーポン削除APIをコールします。
 *
 * @param deleteRequest
 * @return CommonResponse
 */
export async function deleteOne(deleteRequest: DeleteRequest) {
  const response = await service.post("/delete-coupon", deleteRequest);
  return response.data as CommonResponse;
}

/**
 * クーポンバーコード利用状況取得API（/get-coupon-barcode-flg）をコールします。
 *
 * @param barcodeFlgRequest
 * @return BarcodeFlgResponse
 */
export async function get(barcodeFlgRequest: BarcodeFlgRequest) {
  const response = await service.post("/get-coupon-barcode-flg", barcodeFlgRequest);
  return response.data as BarcodeFlgResponse;
}
