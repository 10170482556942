import service from "@/api/service";
import { GetResponse } from "@/api/auth-cord-shop/response";
import { GetRequest } from "@/api/auth-cord-shop/request";

/**
 * クーポン認証コード一覧検索APIをコールします。
 *
 * @return GetResponse
 */
export async function get(getRequest: GetRequest) {
  const response = await service.post("/get-auth-cord-shop", getRequest);
  return response.data as GetResponse;
}
