import { render, staticRenderFns } from "./NewEditNormal.vue?vue&type=template&id=be38fc7e&"
import script from "../../models/coupon/new-edit-normal.ts?vue&type=script&lang=ts&"
export * from "../../models/coupon/new-edit-normal.ts?vue&type=script&lang=ts&"
import style0 from "./NewEditNormal.vue?vue&type=style&index=0&id=be38fc7e&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports