import { Component, Mixins, Prop } from "vue-property-decorator";
import WindowSizeChecker from "@/utils/window-size-checker";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import Flash from "@/store/common/flash";
import RedirectWithAlert from "@/models/mixins/redirect-with-alert";
import { TableOptions } from "@/api/request";
import { GetRequest as GetWriteShopRequest } from "@/api/write-shop/request";
import { GetRequest as GetAuthCordShopRequest } from "@/api/auth-cord-shop/request";
import { DetailRequest, BarcodeFlgRequest } from "@/api/coupon/request";
import { RegisterRequest } from "@/api/coupon/request";
import { GetItem as AuthCodeShopItem } from "@/api/auth-cord-shop/response";
import WriteShopGet from "@/store/write-shop/get";
import AuthCordShopGet from "@/store/auth-cord-shop/get";
import FixedPhraseGet from "@/store/fixed-phrase/get";
import CouponDetail from "@/store/coupon/detail";
import CouponRegister from "@/store/coupon/register";
import BarcodeFlgGet from "@/store/coupon/get";
import { GetKbnRequest } from "@/api/kbn/request";
import KbnGet from "@/store/kbn/get";

export const enum NextLabelEnum {
  NEXT = "次へ",
  SAVE = "保存する"
}

@Component
export default class MixinNewEdit extends Mixins(RedirectWithAlert) {
  @Prop({ type: String, required: false })
  id?: string | null;

  menuProps = {
    closeOnClick: false,
    closeOnContentClick: false,
    disableKeys: true,
    openOnClick: false,
    maxHeight: 304
  };

  editorOption = {
    modules: {
      toolbar: [
        [{ color: [] }, { background: [] }],
        ["bold", "italic", "underline", "strike"],
        [{ size: ["small", false, "large", "huge"] }],
        [{ align: [] }],
        ["link", "image"],
        ["clean"]
      ]
    }
  };

  authCodeHeaders = [
    { text: "店舗ID", value: "id", sortable: false },
    { text: "店舗名", value: "name", sortable: false },
    { text: "認証コード", value: "authCode", sortable: false },
    {
      label: "修正",
      text: "",
      value: "edit",
      sortable: false
    }
  ];

  inputParams = {
    /** クーポンID */
    id: null,
    /** 店舗ID */
    shopId: 0
  } as RegisterRequest;

  /** 区分ID：バーコード種類 */
  kbnBarcodeType: number = 101;

  writeFlg = 0;
  selectShopIds = [] as number[];
  autoUpdateIntervalDay = null as number | null;
  autoUpdateIntervalWeek = null as number | null;
  autoUpdateIntervalMonth = null as number | null;
  autoUpdateIntervalYear = null as number | null;

  // 1ページ目の表示かどうか
  isFirstPage = true;

  // 次へ or 保存のラベル
  nextLabel = NextLabelEnum.NEXT;

  /**
   * クエリパラメータから店舗IDを返却する（必須）
   */
  get shopId() {
    if (this.$route.query.shopId) {
      return this.$route.query.shopId;
    } else {
      return "";
    }
  }

  /**
   * クエリパラメータから店舗名を返却する（必須）
   */
  get shopName() {
    if (this.$route.query.shopName) {
      return this.$route.query.shopName;
    } else {
      return "";
    }
  }

  get isEdit() {
    return !!this.id;
  }

  get isTabletAndPC() {
    return WindowSizeChecker.isTabletAndPC(this);
  }

  get isPhoneAndTablet() {
    return WindowSizeChecker.isPhoneAndTablet(this);
  }

  get couponShopItems() {
    return WriteShopGet.getItems;
  }

  get authCodeItems() {
    return AuthCordShopGet.getItems;
  }

  get fixedPhraseItems() {
    // value が文字列で返却されるため
    return FixedPhraseGet.getItems.map(i => {
      return { id: Number(i.value), name: i.contents };
    });
  }
  /**
   * クーポンバーコード利用フラグ
   */
  get couponBarcodeFlg() {
    return BarcodeFlgGet.getItems.couponBarcodeFlg;
  }

  /**
   * バーコード種類
   */
  get barcodeTypeItems() {
    return KbnGet.getItems.map(i => {
      return { id: Number(i.id), name: i.name };
    });
  }

  async created() {
    
    
    this.inputParams.shopId = Number(this.$route.query.shopId);
    let getErrorMessage = null as string | null;

    const writeShop = this.fetchWriteShop();
    const authCodeShop = this.fetchAuthCodeShop();
    const fixedPhrase = FixedPhraseGet.get();

    const barcodeFlg = {
      shopId: this.inputParams.shopId
    } as BarcodeFlgRequest;
    const getBarcodeFlg = BarcodeFlgGet.get(barcodeFlg);

    const barcodeType = {
      id: this.kbnBarcodeType
    } as GetKbnRequest;
    const getBarcodeType = KbnGet.get(barcodeType);

    await Promise.all([writeShop, authCodeShop, fixedPhrase, getBarcodeType, getBarcodeFlg]);
    if (!writeShop) {
      getErrorMessage = WriteShopGet.getMessage;
    }
    if (!authCodeShop) {
      getErrorMessage = AuthCordShopGet.getMessage;
    }
    if (!FixedPhraseGet.isSuccess) {
      getErrorMessage = FixedPhraseGet.getMessage;
    }
    if (!BarcodeFlgGet.isSuccess) {
      getErrorMessage = BarcodeFlgGet.getMessage;
    }
    if (!KbnGet.isSuccess) {
      getErrorMessage = KbnGet.getMessage;
    }
    if (getErrorMessage) {
      await Flash.setErrorNow({
        message: getErrorMessage,
        showReloadButton: true
      });
      return;
    }
  }

  async beforeDestroy() {
    await WriteShopGet.clearResponse();
    await AuthCordShopGet.clearResponse();
    await FixedPhraseGet.clearResponse();
    await CouponDetail.clearResponse();
    await CouponRegister.clearResponse();
  }

  /**
   * ページング変更コールバック
   *
   * @param tableOptions TableOptions
   */
  async authCodeTableChangeCallback(tableOptions: TableOptions) {}

  /**
   * テーブル行の編集ボタンがクリックされた際のコールバック
   *
   * @param item 選択行のGetItem
   */
  async authCodeEditClickCallback(item: AuthCodeShopItem) {
    await this.$router.push({
      name: "auth-code-edit",
      params: { id: String(item.id) },
      query: { authType: "2" } // クーポン
    });
  }

  goBack() {
    if (this.isFirstPage) {
      this.$router.back();
    } else {
      window.scrollTo(0, 0);
      this.nextLabel = NextLabelEnum.NEXT;
      this.isFirstPage = true;
    }
  }

  async fetchWriteShop(): Promise<boolean> {
    await WriteShopGet.get({
      shopId: Number(this.shopId)
    } as GetWriteShopRequest);
    return WriteShopGet.isSuccess;
  }

  async fetchAuthCodeShop(): Promise<boolean> {
    await AuthCordShopGet.get({
      shopId: Number(this.shopId)
    } as GetAuthCordShopRequest);
    return AuthCordShopGet.isSuccess;
  }

  async fetchDetail(): Promise<boolean> {
    await CouponDetail.detail({
      id: Number(this.id),
      shopId: Number(this.shopId)
    } as DetailRequest);
    return CouponDetail.isSuccess;
  }
}
