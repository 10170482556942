import {
  getModule,
  Module,
  MutationAction,
  VuexModule
} from "vuex-module-decorators";
import store from "@/store";
import { GetKbnResponse } from "@/api/kbn/response";
import * as KbnGet from "@/api/kbn";
import { isSuccess } from "@/api/response";
import { GetKbnRequest } from "@/api/kbn/request";

const MODULE_NAME = "kbn/get";

/**
 * 区分情報取得（共通）API（/get-kbn）のレスポンスを管理するストアモジュール
 */
@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class Get extends VuexModule {
  // state
  getResponse: GetKbnResponse = {} as GetKbnResponse;

  // getters
  get isSuccess() {
    return isSuccess(this.getResponse);
  }

  get getMessage() {
    return this.getResponse.message;
  }

  get getItems() {
    if (this.getResponse.results) {
      return this.getResponse.results.items || [];
    } else {
      return [];
    }
  }

  // MutationActions
  @MutationAction
  async get(request: GetKbnRequest) {
    const getResponse = await KbnGet.get(request);
    return {
      getResponse
    };
  }

  @MutationAction
  async clearResponse() {
    return {
      getResponse: {} as GetKbnResponse
    };
  }
}

export default getModule(Get);
