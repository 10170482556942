import {
  getModule,
  Module,
  MutationAction,
  VuexModule
} from "vuex-module-decorators";
import store from "@/store";
import { BarcodeFlgResponse } from "@/api/coupon/response";
import * as BarcodeFlgAPI from "@/api/coupon";
import { isSuccess } from "@/api/response";
import { GetItem } from "@/api/coupon/response";
import { BarcodeFlgRequest } from "@/api/coupon/request";

const MODULE_NAME = "coupon/get";

/**
 * クーポンバーコード利用状況取得API（/get-coupon-barcode-flg）のレスポンスを管理するストアモジュール
 */
@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class Get extends VuexModule {
  // state
  getResponse: BarcodeFlgResponse = {} as BarcodeFlgResponse;

  // getters
  get isSuccess() {
    return isSuccess(this.getResponse);
  }

  get getMessage() {
    return this.getResponse.message;
  }

  get getItems() {
    if (this.getResponse.results) {
      return this.getResponse.results.items || {} as GetItem;
    } else {
      return {} as GetItem;
    }
  }

  // MutationActions
  @MutationAction
  async get(request: BarcodeFlgRequest) {
    const getResponse = await BarcodeFlgAPI.get(request);
    return {
      getResponse
    };
  }

  @MutationAction
  async clearResponse() {
    return {
      getResponse: {} as BarcodeFlgResponse
    };
  }
}

export default getModule(Get);
