




























































































import { Component, PropSync, Vue } from "vue-property-decorator";
import UIFormRow from "@/components/UIFormRow.vue";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";

@Component({
  components: { UIFormRow, quillEditor }
})
export default class UILotteryField extends Vue {
  @PropSync("useLottery")
  pUseLottery!: number;

  @PropSync("shopName")
  pShopName!: string;

  @PropSync("lotteryCount")
  pLotteryCount!: string;

  @PropSync("lotteryProbability")
  pLotteryProbability!: string;

  @PropSync("lotteryContent")
  pLotteryContent!: string | null;

  editorOption = {
    modules: {
      toolbar: [
        [{ color: [] }, { background: [] }],
        ["bold", "italic", "underline", "strike"],
        [{ size: ["small", false, "large", "huge"] }],
        [{ align: [] }],
        ["link", "image"],
        ["clean"]
      ]
    }
  };

  lotterypreview() {
      this.$emit('lotterypreview')
  }

}
