import service from "@/api/service";
import { GetResponse } from "@/api/kbn/response";
import { GetRequest } from "@/api/kbn/request";

/**
 * 区分情報取得（共通）APIをコールします。
 * 
 * @param request
 * @return GetResponse
 */
export async function get(request: GetRequest) {
  const response = await service.post("/get-kbn", request);
  return response.data as GetResponse;
}
