import { Component, Mixins } from "vue-property-decorator";
import UIFormRow from "@/components/UIFormRow.vue";
import UIDataTable from "@/components/UIDataTable.vue";
import UIDatePicker from "@/components/UIDatePicker.vue";
import UILotteryField from "@/components/coupon/UILotteryField.vue";
import RedirectWithAlert from "@/models/mixins/redirect-with-alert";
import MixinNewEdit, { NextLabelEnum } from "@/models/coupon/mixin-new-edit";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";
import CouponRegister from "@/store/coupon/register";
import CouponDetail from "@/store/coupon/detail";
import _ from "lodash";
import Flash from "@/store/common/flash";
import { RegisterRequest } from "@/api/coupon/request";
import WindowOpen from "@/utils/window-open";
import { BusinessServiceStorage } from "@/store/business-service-storage";

@Component({
  components: {
    UIFormRow,
    UIDataTable,
    UIDatePicker,
    UILotteryField,
    quillEditor
  }
})
export default class NewEditLimitation extends Mixins(
  RedirectWithAlert,
  MixinNewEdit
) {
  inputParams = {
    /** クーポンID */
    id: null,
    /** 店舗ID */
    shopId: 0,
    /** クーポン種別 1:通常クーポン、2:初回限定クーポン、3:誕生日クーポン */
    distributionType: 1,
    /** 選択店舗IDリスト */
    selectShopIds: [],
    /** オファー限定フラグ 0:使用しない、1:使用する */
    offerFlg: 0,
    /** 公開設定 0:非公開、1:公開 */
    viewFlg: 0,
    /** クーポン有効期限From（通常クーポン） */
    startDate: null,
    /** クーポン有効期限End（通常クーポン） */
    endDate: null,
    /** クーポン有効期限 ダウンロード後時間指定（初回限定クーポン） */
    firstGiftExpirationHours: null,
    /** クーポン有効期限（お誕生日クーポン） */
    birthdayLimit: 1,
    /** ポップアップ表示（初回限定クーポン） 0:使用しない、1:使用する */
    firstGiftPopupFlg: null,
    /** クーポン事前表示 */
    publishStartDate: null,
    /** 自動更新機能フラグ 0:使用しない、1:使用する */
    autoUpdateFlg: 0,
    /** 自動更新機能周期タイプ 1:日、2:週、3:月、4:年 */
    autoUpdateType: 1,
    /** 自動更新周期 */
    autoUpdateInterval: null,
    /** クーポンタイトル */
    title: "",
    /** 割引内容 */
    discountType: null,
    /** クーポン詳細 */
    comment: "",
    /** 注意書き */
    attention: null,
    /** 注意書き定型文 */
    textType: [],
    /** クーポン使用済みチェック機能フラグ 0:使用しない、1:使用する */
    checkFlg: 0,
    /** クーポン利用可能回数 */
    usageCountLimit: null,
    /** クーポン使用認証設定 0:使用しない、1:使用する */
    authFlg: 0,
    /** 当選機能 0:使用しない、1:使用する */
    lotteryFlg: 0,
    /** 当選数 */
    lotteryWinnersCountLimit: null,
    /** 当選確率 0〜100 */
    probabilityOfWinning: null,
    /** 当選前画面 */
    lotteryComment: null,
    /** プレビュー（0:保存, 1:プレビュー）*/
    isPreview: 0,
    /** 抽選前プレビュー（0:通常, 1:抽選前*/
    isLotteryPreview: 0,
    /**  バーコード利用フラグ 0:使用しない、1:使用する */
    barcodeFlg: 0,
    /**  バーコード種類 1:EANコード（13桁）、2:Code128（20桁・数字） */
    barcodeType: null,
    /**  バーコード番号 */
    barcode: ""
  } as RegisterRequest;

  /**代理店判別businessId*/
  businessId = 0 as number;

  async created() {
    if (this.isEdit) {
      await this.fetchDetail();
      if (!CouponDetail.isSuccess) {
        await Flash.setErrorNow({
          message: CouponDetail.getMessage,
          showReloadButton: true
        });
        return;
      }
      this.syncInputParams();
    } else {
      const date = new Date();
      const dateStr = `${date.getFullYear()}-${(
        "00" +
        (date.getMonth() + 1)
      ).slice(-2)}-${("00" + date.getDate()).slice(-2)}`;
      this.inputParams.startDate = dateStr;
      this.inputParams.endDate = dateStr;
      this.selectShopIds.push(Number(this.shopId));
    }
  }
  
  async mounted(){
    const storage = BusinessServiceStorage.getLocalStorage();
    this.businessId = storage.id ? storage.id : 0;
  }

  async goForward() {
    //NEW_DEV-1530 cyber start
    this.inputParams.isPreview = 0;
    //NEW_DEV-1530 cyber end
    if (this.nextLabel === NextLabelEnum.SAVE) {
      const isSuccess = await this.callRegister();
      if (isSuccess) {
        await Flash.clear();
        await this.redirectWithSuccessAlert(
          "クーポンを保存しました。",
          `/coupon/${this.inputParams.shopId}/list?shopName=${this.shopName}`
        );
      } else {
        await Flash.setErrorNow({
          message: CouponRegister.getMessage,
          showReloadButton: false
        });
      }
    } else {
      window.scrollTo(0, 0);
      this.nextLabel = NextLabelEnum.SAVE;
      this.isFirstPage = false;
    }
  }
  async preview() {
    //NEW_DEV-1530 cyber start
    this.inputParams.isPreview = 1;
    //NEW_DEV-1530 cyber end
    this.inputParams.isLotteryPreview = 0;
    const isSuccess = await this.callRegister();
    if (isSuccess) {
      WindowOpen.preview(CouponRegister.getPreviewUrl);
    }
  }

  async lotterypreview() {
    this.inputParams.isPreview = 1;
    this.inputParams.isLotteryPreview = 1;
    const isSuccess = await this.callRegister();
    if (isSuccess) {
      WindowOpen.preview(CouponRegister.getPreviewUrl);
    }
  }

  private async callRegister(): Promise<boolean> {
    await CouponRegister.register(this.createRegisterRequest());
    return CouponRegister.isSuccess;
  }

  private syncInputParams() {
    const detail = { ...CouponDetail.getDetail };
    this.writeFlg = detail.writeFlg;
    this.selectShopIds = detail.shopIds.map(s => s);
    this.autoUpdateIntervalDay = detail.autoUpdateIntervalDay;
    this.autoUpdateIntervalWeek = detail.autoUpdateIntervalWeek;
    this.autoUpdateIntervalMonth = detail.autoUpdateIntervalMonth;
    this.autoUpdateIntervalYear = detail.autoUpdateIntervalYear;
    delete detail.writeFlg;
    delete detail.shopIds;
    delete detail.autoUpdateIntervalDay;
    delete detail.autoUpdateIntervalWeek;
    delete detail.autoUpdateIntervalMonth;
    delete detail.autoUpdateIntervalYear;
    this.inputParams = {
      ...CouponDetail.getDetail,
      shopId: Number(this.shopId),
      selectShopIds: [],
      autoUpdateInterval: null,
      isPreview: 0
    };
  }

  private createRegisterRequest() {
    const request = { ...this.inputParams, distributionType: 2 };
    request.selectShopIds = _.cloneDeep(this.selectShopIds);
    if (Number(request.autoUpdateFlg) === 1) {
      switch (Number(request.autoUpdateType)) {
        case 1:
          request.autoUpdateInterval = this.autoUpdateIntervalDay;
          break;
        case 2:
          request.autoUpdateInterval = this.autoUpdateIntervalWeek;
          break;
        case 3:
          request.autoUpdateInterval = this.autoUpdateIntervalMonth;
          break;
        case 4:
          request.autoUpdateInterval = this.autoUpdateIntervalYear;
          break;
      }
    }
    if (request.probabilityOfWinning) {
      request.probabilityOfWinning = Number(request.probabilityOfWinning);
    }
    return request;
  }
}
